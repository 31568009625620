<template>
  <!-- 资料库>资料管理 -->
  <div class="dict">
    <div class="form-header-btn">
      <div class="header-title">资料管理</div>
      <div></div>
    </div>
    <el-container class="container">
      <el-aside width="13.85417vw" class="aside">
        <el-tree
          node-key="id"
          ref="tree"
          :data="directoryTree"
          :props="defaultProps"
          accordion
          :default-expanded-keys="defaultKey"
          @node-click="clickChildrenNode"
        >
          <span class="custom-tree-node" slot-scope="{ node }">
            <el-tooltip
              v-if="node.level == 1 && node.label.length >= 13"
              class="item"
              effect="dark"
              :content="node.label"
              placement="top"
            >
              <span class="nodeLabel">{{ node.label }}</span>
            </el-tooltip>
            <el-tooltip
              v-else-if="node.level == 2 && node.label.length >= 12"
              class="item"
              effect="dark"
              :content="node.label"
              placement="top"
            >
              <span class="nodeLabel">{{ node.label }}</span>
            </el-tooltip>
            <el-tooltip
              v-else-if="node.level == 3 && node.label.length >= 9"
              class="item"
              effect="dark"
              :content="node.label"
              placement="top"
            >
              <span class="nodeLabel">{{ node.label }}</span>
            </el-tooltip>
            <span v-else>{{ node.label }}</span>
          </span>
        </el-tree>
      </el-aside>
      <el-container class="container" v-if="!isRecycleBin">
        <el-header>
          <!-- 表单 --><el-form
            :model="form"
            onsubmit="return false"
            :inline="true"
            class="search-form"
          >
            <el-form-item>
              <el-input v-model="form.keyword" placeholder="文件名称" />
            </el-form-item>

            <el-form-item>
              <el-button type="primary" icon="el-icon-search" @click="searchFun"
                >查询</el-button
              >
            </el-form-item>

            <el-form-item>
              <el-button icon="el-icon-refresh" @click="resetFun"
                >重置</el-button
              >
            </el-form-item>
          </el-form>
        </el-header>
        <el-main style="padding-top: 0">
          <div class="mianDiv">
            <!-- <el-tooltip
              effect="dark"
              v-if="isAdd"
              content="新增后点击查询即可看到最新数据"
              placement="top-start"
            > -->
            <el-button type="primary" @click="addFile">新增资料</el-button>
            <!-- </el-tooltip> -->
            <el-button
              size="medium"
              v-if="isBatchDelete && userInfo.userId === currentCatalogId"
              @click="delFiles"
              >批量删除</el-button
            >
          </div>
          <l-table
            :columns="columns"
            :dataSource="tableData"
            :pagination="pageFlag"
            :ipagination="page"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
            :loading="tableLoading"
            :showXh="true"
            :height="'calc(100% - 2.91667vw)'"
            :autoHeight="true"
            :showCheckBox="true"
            :checkBoxFixed="'left'"
            @selection-change="selectRowChange"
            @select="handleSelect"
            @select-all="handleSelectAll"
          >
            <el-table-column fixed="left" type="selection" min-width="40">
            </el-table-column>
            <el-table-column
              prop="fileName"
              label="文件名称"
              align="center"
              min-width="300"
              slot="fileName"
            ></el-table-column>
            <el-table-column
              prop="size"
              label="文件大小"
              align="center"
              slot="size"
              min-width="150"
            >
              <template slot-scope="scope">
                <span v-if="1048576 > scope.row.size"
                  >{{ (scope.row.size / 1024).toFixed(2) }}KB</span
                >
                <span v-else
                  >{{ (scope.row.size / 1024 / 1024).toFixed(2) }}MB</span
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="createName"
              label="创建人"
              min-width="100"
              align="center"
              slot="createName"
            ></el-table-column>
            <el-table-column
              prop="updateTime"
              slot="updateTime"
              min-width="150"
              label="更新时间"
              align="center"
            ></el-table-column>
            <!-- type="expand" -->
            <el-table-column>
              <template slot-scope="scope">
                {{ scope.row.createName }}
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              slot="handle"
              align="center"
              min-width="230"
              fixed="right"
            >
              <template slot-scope="scope">
                <div>
                  <el-button
                    size="mini"
                    type="text"
                    v-if="isViewFile"
                    @click="showAttachment(scope.row.id)"
                    ><span>查看附件</span>
                  </el-button>
                  <el-button
                    size="small"
                    type="text"
                    v-if="isView"
                    @click="editOrShow('show', scope.row.id)"
                    ><span>查看</span>
                  </el-button>
                  <el-button
                    size="small"
                    type="text"
                    v-if="isUpdate && isMine(scope.row.createBy)"
                    @click="editOrShow('edit', scope.row.id)"
                    ><span>编辑</span>
                  </el-button>
                  <el-button
                    size="small"
                    type="text"
                    v-if="isDelete && isMine(scope.row.createBy)"
                    @click="handleDel(scope.row.id)"
                    ><span>删除</span>
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </l-table>
        </el-main>
      </el-container>
      <el-container class="container" v-else>
        <el-header>
          <el-form
            :model="formRecycleBin"
            onsubmit="return false"
            :inline="true"
            class="search-form"
          >
            <el-form-item>
              <el-input
                v-model="formRecycleBin.keyword"
                placeholder="文件名称"
              />
            </el-form-item>

            <el-form-item>
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="searchRecycleBin()"
                >查询</el-button
              >
            </el-form-item>

            <el-form-item>
              <el-button
                @click="resetRecycleBin()"
                icon="el-icon-refresh"
                class="reset"
                >重置</el-button
              >
            </el-form-item>
          </el-form>
        </el-header>
        <el-main style="padding-top: 0">
          <div class="mianDiv">
            <!--  v-if="isBatchDelete" -->
            <el-button type="primary" size="medium" @click="selectDel"
              >批量删除</el-button
            >
            <el-button size="medium" @click="clearRecycle"
              >清空回收站</el-button
            >
          </div>
          <l-table
            :columns="binColumns"
            :dataSource="binTableData"
            :pagination="binPageFlag"
            :ipagination="binPage"
            @current-change="handleCurrentChangeBin"
            @size-change="handleSizeChangeBin"
            :loading="tableLoadingBin"
            :showXh="true"
            :height="'calc(100% - 2.91667vw)'"
            :autoHeight="true"
            :showCheckBox="true"
            :checkBoxFixed="'left'"
            @selection-change="selectRowChangeBin"
            @select="handleSelectBin"
            @select-all="handleSelectAllBin"
          >
            <el-table-column fixed="left" type="selection" min-width="55">
            </el-table-column>
            <el-table-column
              prop="fileName"
              label="文件名称"
              align="center"
              min-width="300"
              slot="fileName"
            ></el-table-column>
            <el-table-column
              prop="createName"
              label="创建人"
              align="center"
              slot="createName"
              min-width="100"
            >
            </el-table-column>
            <el-table-column
              prop="size"
              label="文件大小"
              align="center"
              slot="size"
              min-width="150"
            >
              <template slot-scope="scope">
                <!-- {{ (scope.row.size / 1024 / 1024).toFixed(2) }}MB -->
                <span v-if="1048576 > scope.row.size"
                  >{{ (scope.row.size / 1024).toFixed(2) }}KB</span
                >
                <span v-else
                  >{{ (scope.row.size / 1024 / 1024).toFixed(2) }}MB</span
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="updateBy"
              label="删除人"
              min-width="100"
              align="center"
              slot="updateBy"
            ></el-table-column>
            <el-table-column
              prop="updateTime"
              slot="updateTime"
              min-width="150"
              label="删除时间"
              align="center"
            ></el-table-column>
            <!-- type="expand" -->
            <el-table-column>
              <template slot-scope="scope">
                {{ scope.row.createName }}
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              slot="handle"
              align="center"
              min-width="230"
              fixed="right"
            >
              <template slot-scope="scope">
                <div>
                  <el-button
                    size="mini"
                    type="text"
                    @click="delRecycleFiles(scope.row.id)"
                    ><span>删除</span>
                  </el-button>
                  <el-button
                    size="small"
                    type="text"
                    @click="recovery(scope.row.id)"
                    ><span>恢复到原位</span>
                  </el-button>
                  <el-button
                    size="small"
                    type="text"
                    @click="recovery(scope.row.id, true)"
                    ><span>恢复到指定的位置</span>
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </l-table>

          <el-dialog
            title="恢复到指定位置"
            :visible.sync="positionPanel"
            width="30%"
            @close="clearBin"
          >
            <span class="activeRar">活动区域</span>
            <el-cascader
              :show-all-levels="false"
              style="width: 100%"
              v-model="parentId"
              :options="specifiedLocationTree"
              :props="treeProps"
            ></el-cascader>
            <span slot="footer" class="dialog-footer">
              <el-button @click="positionPanel = false">取 消</el-button>
              <el-button type="primary" @click="recoveryToDirectory"
                >确 定</el-button
              >
            </span>
          </el-dialog>
        </el-main>
      </el-container>
    </el-container>
    <el-dialog title="查看附件" :visible.sync="attachmentVisible">
      <!-- <div class="downLoadFile" >
        <el-button type="primary" @click="downLoadFile(null, 'pack')">打包下载</el-button>
      </div> -->
      <el-table
        :header-cell-style="{
          background: '#F1F4F9',
          color: '#5E6D9B',
          fontWeight: '400',
        }"
        :data="attachmentData"
        v-loading="downLoading"
      >
        <el-table-column
          align="center"
          property="type"
          min-width="100"
          label="类型"
        >
          <template slot-scope="scope">
            <span class="fileType" v-if="scope.row.type == 1">
              <img
                v-if="
                  scope.row.attachment_type.indexOf('xl') != -1 ||
                  scope.row.attachment_type.indexOf('XL') != -1
                "
                src="@/assets/img/board/excel.png"
                alt=""
              />
              <img
                v-if="
                  scope.row.attachment_type.indexOf('doc') != -1 ||
                  scope.row.attachment_type.indexOf('DOC') != -1
                "
                src="@/assets/img/board/word.png"
                alt=""
              />
              <img
                v-if="
                  scope.row.attachment_type.indexOf('pdf') != -1 ||
                  scope.row.attachment_type.indexOf('PDF') != -1
                "
                src="@/assets/img/board/pdf.png"
                alt=""
              />
              <img
                v-if="
                  scope.row.attachment_type.indexOf('pp') != -1 ||
                  scope.row.attachment_type.indexOf('PP') != -1
                "
                src="@/assets/img/board/ppt.png"
                alt=""
              />
              文档</span
            >
            <span class="fileType" v-else-if="scope.row.type == 2"
              ><img src="@/assets/img/board/video.png" alt="" />视频</span
            >
            <span class="fileType" v-else-if="scope.row.type == 3"
              ><img src="@/assets/img/board/img.png" alt="" />图片</span
            >
            <i class="el-icon-question" v-else>其他</i>
          </template>
        </el-table-column>
        <el-table-column
          property="attachment_name"
          align="center"
          label="附件名称"
          min-width="150"
        >
          <template slot-scope="scope"
            >{{ scope.row.attachment_name }}.{{
              scope.row.attachment_type
            }}</template
          >
        </el-table-column>

        <el-table-column
          min-width="100"
          align="center"
          property="size"
          label="附件大小"
        >
          <template slot-scope="scope">
            <!-- {{ (scope.row.size / 1024 / 1024).toFixed(2) }}MB -->
            <span v-if="1048576 > scope.row.size"
              >{{ (scope.row.size / 1024).toFixed(2) }}KB</span
            >
            <span v-else
              >{{ (scope.row.size / 1024 / 1024).toFixed(2) }}MB</span
            >
          </template></el-table-column
        >
        <el-table-column
          align="center"
          property="name"
          label=""
          min-width="250"
        >
          <template slot="header">
            <!-- <el-button v-if="attachmentData && attachmentData[0] && attachmentData[0].authority.indexOf('2') != -1" @click="downLoadFile(null,'pack')" type="text">打包下载</el-button> -->
            <el-button
              v-if="
                attachmentData &&
                attachmentData[0] &&
                attachmentData[0].authority == '1,2'
              "
              @click="downLoadFile(null, 'pack2')"
              type="text"
              >打包下载</el-button
            >
            <el-button
              v-if="
                attachmentData &&
                attachmentData[0] &&
                attachmentData[0].authority == '1,2,3'
              "
              @click="downLoadFile(null, 'pack1')"
              type="text"
              >打包下载</el-button
            >
          </template>
          <template slot-scope="scope">
            <div>
              <el-button
                v-if="
                  scope.row.authority && scope.row.authority.indexOf('1') != -1
                "
                size="mini"
                type="text"
                @click="showFile(scope.row)"
                ><span class="wordColor2">查看</span>
              </el-button>
              <el-button
                size="small"
                type="text"
                v-if="
                  scope.row.authority && scope.row.authority.indexOf('2') != -1
                "
                @click="downLoadFile(scope.row.id, 'pdf', scope.row.type)"
                ><span
                  :class="scope.row.type == 2 ? 'wordColor3' : 'wordColor2'"
                  >下载PDF</span
                >
              </el-button>
              <el-button
                size="small"
                type="text"
                v-if="
                  scope.row.authority && scope.row.authority.indexOf('3') != -1
                "
                @click="downLoadFile(scope.row.id, 'file')"
                ><span class="wordColor2">下载原文件</span>
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 对话框 -->
    <el-dialog
      :title="title == '0' ? '新增' : '编辑'"
      :visible.sync="dialogVisible"
      width="38%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <el-form
        :model="dialogForm"
        label-width="5.20833vw"
        :rules="rules"
        ref="dialogForm"
      >
        <el-form-item class="form-choose-item" prop="type" label="类型">
          <el-input
            class="form-input"
            v-model="dialogForm.type"
            clearable
            :maxlength="50"
            placeholder="请输入类型"
          />
        </el-form-item>
        <el-form-item class="form-choose-item" prop="name" label="标签名">
          <el-input
            class="form-input"
            v-model="dialogForm.name"
            clearable
            :maxlength="50"
            placeholder="请输入标签名"
          />
        </el-form-item>
        <el-form-item class="form-choose-item" prop="value" label="数据值">
          <el-input
            class="form-input"
            v-model="dialogForm.value"
            clearable
            :maxlength="50"
            placeholder="请输入数据值"
          />
        </el-form-item>

        <el-form-item class="form-choose-item" prop="description" label="描述">
          <el-input
            class="form-input"
            v-model="dialogForm.description"
            clearable
            :maxlength="50"
            placeholder="请输入描述"
          />
        </el-form-item>
        <el-form-item class="form-choose-item" prop="sort" label="排序">
          <el-input
            class="form-input"
            v-model="dialogForm.sort"
            clearable
            v-input-number
            :maxlength="3"
            @keyup.native="getInputChange(dialogForm.sort, 'sort')"
            placeholder="请填写排序，只支持数字"
          />
        </el-form-item>
        <el-form-item class="mt0">
          <el-button
            type="primary"
            v-if="title == '0'"
            :disabled="editLoading"
            :loading="editLoading"
            @click="dialogAdd('dialogForm')"
            >新增</el-button
          >
          <el-button
            type="primary"
            :disabled="editLoading"
            :loading="editLoading"
            v-else
            @click="handleEditBtn('dialogForm')"
            >修改</el-button
          >
          <el-button @click="handleClose">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <onlinePreview
      :onlinePreviewVisible="onlinePreviewVisible"
      :type="fileAttachment.previewType"
      @closeOnlinePreviewDialog="closeOnlinePreviewDialog"
      :url="fileAttachment.url"
      :title="
        (fileAttachment.attachment_name || '') +
        '.' +
        (fileAttachment.attachment_type || '')
      "
    />
  </div>
</template>

<script>
import LTable from "@/components/tableComponent/index.vue";
import downloadFile from "@/utils/downloadFile.js";
import onlinePreview from "@/components/onlinePreview/index.vue";

import {
  getPermissionButton,
  isHasPermission,
} from "@/utils/getUrlPermissionButtons";
import { mapGetters, mapState } from "vuex";
export default {
  name: "dataManage",
  components: {
    LTable,
    onlinePreview,
  },
  data() {
    var checkNum = (rule, value, callback) => {
      if (!value) {
        return callback();
      }
      let valueString = value + "";
      let numberAuth = /[^\d]/g;
      if (numberAuth.test(valueString)) {
        callback(new Error("只支持数字"));
      } else if (valueString.length > 1 && valueString.substr(0, 1) === "0") {
        callback(new Error("只支持数字，首位不能是0"));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      tableLoading: false, // tableloading
      attachmentVisible: false,
      onlinePreviewVisible: false,
      fileAttachment: {},
      attachmentData: [],
      selectData: [],
      downLoading: false,
      directoryId: "",
      page: {
        num: 1,
        size: 10,
        total: 0,
      }, // 分页信息
      pageFlag: false,
      columns: [
        {
          label: "文件名称",
          slotName: "fileName",
        },
        {
          label: "文件大小",
          slotName: "size",
        },
        {
          label: "创建人",
          slotName: "createName",
        },

        {
          label: "更新时间",
          slotName: "updateTime",
        },
        {
          label: "操作",
          slotName: "handle",
        },
      ],
      title: "",
      form: {
        keyword: "",
        directoryId: "",
        options: [
          {
            label: "异常",
            value: "0",
          },
          {
            label: "正常",
            value: "1",
          },
        ],
      },
      dialogForm: {
        type: "",
        name: "",
        value: "",
        description: "",
        sort: "",
      },
      tableData: [],
      //   pagination: {
      //     //分页信息
      //     total: 30, // 总页数
      //     currentPage: 1, // 当前页数
      //     Size: 10, // 每页显示多少条
      //   },
      // 对话框表单校验
      rules: {
        name: [
          {
            required: true,
            message: "请输入标签名",
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: "请输入类型",
            trigger: "blur",
          },
        ],
        value: [
          {
            required: true,
            message: "请输入数据值",
            trigger: "blur",
          },
          // { min: 3, max: 50, message: '长度在 3 到 50 个字符', trigger: 'blur' }
        ],
        description: [
          {
            required: true,
            message: "请输入描述",
            trigger: "blur",
          },
        ],
        sort: [
          {
            required: true,
            message: "请填写排序，只支持数字",
            trigger: "blur",
          },
          { validator: checkNum, trigger: "blur" },
        ],
      },
      permissionButtonList: [], // 权限按钮list
      isUpdate: false,
      isAdd: false,
      isDelete: false,
      directoryTree: [],
      specifiedLocationTree: [],
      defaultProps: {
        children: "child",
        label: "directoryName",
      },
      defaultKey: [],
      isRecycleBin: false,
      formRecycleBin: {
        keyword: "",
      },
      binColumns: [
        {
          label: "文件名称",
          slotName: "fileName",
        },
        {
          label: "文件大小",
          slotName: "size",
        },
        {
          label: "创建人",
          slotName: "createName",
        },

        {
          label: "更新时间",
          slotName: "updateTime",
        },
        {
          label: "操作",
          slotName: "handle",
        },
      ],
      tableLoadingBin: false,
      positionPanel: false,
      binPage: {
        num: 1,
        size: 10,
        total: 0,
      }, // 分页信息
      binPageFlag: false,
      binTableData: [],
      directoryList: [],
      parentId: [],
      treeProps: {
        value: "id",
        label: "directoryName",
        children: "child",
      },
      fileId: "",
      bindelList: [],
      isBatchDelete: false,
      isView: false,
      isViewFile: false,
      isDownFile: false,
      loadingData: false,
      editLoading: false,
      currentCatalogId: "", // 当前选中的目录ID
      isMounted: false, // 标识是否被缓存
    };
  },
  computed: {
    ...mapGetters(["permissions"]),
    ...mapState({
      menuList: (state) => state.common.menuList,
      userInfo: (state) => state.userInfo,
    }),
  },
  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          this.isAdd = val.indexOf("dataManage:add") != -1; //添加
          this.isUpdate = val.indexOf("dataManage:edit") != -1; //编辑
          this.isDelete = val.indexOf("dataManage:del") != -1; //删除
          this.isBatchDelete = val.indexOf("dataManage:batchDel") != -1; //批量删除
          this.isView = val.indexOf("dataManage:view") != -1; //查看
          this.isViewFile = val.indexOf("dataManage:viewFile") != -1; //查看文件
          this.isDownFile = val.indexOf("dataManage:downFile") != -1; //下载文件
        }
      },
      deep: true,
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.isMounted = false;
    // menulist一直在时这个方法不可缺少
    this.getPermissionButton(this.$route.path, (data) => {
      this.permissionButtonList = data;
    });
    this.tableLoading = true;
    this.getDirectoryTree();
  },
  activated() {
    if (this.isMounted) {
      this.defaultKey = [];
      this.getDirectoryTree();
    }
  },
  methods: {
    getPermissionButton,
    isHasPermission,
    /**
     * 判断当前这条记录是自己的或者目录的创建者是自己
     * 20221020
     */
    isMine(rowUser) {
      return (
        this.userInfo.userId === rowUser ||
        this.userInfo.userId === this.currentCatalogId
      );
    },
    getDirectoryTree() {
      //获取目录树
      this.$api.getThirdDirectory().then((res) => {
        this.directoryTree = JSON.parse(JSON.stringify(res.data.data));
        if (res.data.code == "0") {
          if (
            this.$store.state.userInfo.authList[0] == "org:z" &&
            this.directoryTree.length > 0
          ) {
            // 总部
            this.directoryTree.push({
              directoryName: "回收站",
              id: "000",
            });
          }

          this.specifiedLocationTree = JSON.parse(
            JSON.stringify(res.data.data)
          );
          // 判断当前是否缓存，如果缓存选中上一次的缓存值，否则按照初始化的方式走
          if (this.isMounted) {
            this.$nextTick(() => {
              let id = this.$refs.tree.getCurrentKey();
              this.$refs.tree.setCurrentKey(id);
              this.form.directoryId = id;
              this.defaultKey = [id];
              this.init();
            });
          } else {
            this.isMounted = true;
            if (this.directoryTree) {
              if (this.directoryTree.length > 0) {
                if (
                  this.directoryTree[0].child &&
                  this.directoryTree[0].child.length > 0
                ) {
                  this.currentCatalogId =
                    this.directoryTree[0].child[0].child[0].createBy;
                  this.$nextTick(() => {
                    this.$refs.tree.setCurrentKey(
                      this.directoryTree[0].child[0].child[0].id
                    );
                  });
                }
              }
              if (
                JSON.parse(sessionStorage.getItem("dataManage")).form ==
                  "showManage" ||
                JSON.parse(sessionStorage.getItem("dataManage")).form ==
                  "createManage"
              ) {
                this.form.directoryId =
                  this.directoryTree.length > 0
                    ? this.directoryTree[0].child[0].child[0].id
                    : JSON.parse(sessionStorage.getItem("dataManage"))
                        .directoryId;
                this.init();
                this.defaultKey.push(this.form.directoryId);
              } else {
                this.form.directoryId =
                  this.directoryTree.length > 0
                    ? this.directoryTree[0].child[0].child[0].id
                    : "";
                this.init();
                this.directoryTree.forEach((e, i) => {
                  if (i == 0) {
                    this.defaultKey.push(e.id);
                    this.defaultKey.push(e.child[0].id);
                  }
                });
              }
            } else {
              this.init();
            }
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    initBin() {
      let params = Object.assign({
        current: this.binPage.num,
        size: this.binPage.size,
        fileName: this.formRecycleBin.keyword,
      });
      this.tableLoadingBin = true;
      this.$api
        .getRecycleList(params)
        .then((res) => {
          this.tableLoadingBin = false;
          if (res.data.code == "0") {
            this.binTableData = res.data.data.records;
            this.binPage.total = res.data.data.total;
            if (res.data.data.total >= 1) {
              this.binPageFlag = true;
            } else {
              this.binPageFlag = false;
            }
          } else {
            this.binTableData = [];
            this.binPage.total = 0;
            this.binPageFlag = false;
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {
          this.tableLoadingBin = false;
        });
    },
    // 回收站搜索按钮
    searchRecycleBin() {
      this.binPage.num = 1;
      this.initBin();
    },
    // 重置回收站搜索条件
    resetRecycleBin() {
      // this.form.keyword = "";
      this.formRecycleBin.keyword = "";
      this.binPage.num = 1;
      this.initBin();
    },
    // 删除单个回收站
    delRecycleFiles(id) {
      this.$confirm("删除后文件将不能恢复,确定删除吗?", "确认删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        customClass: "addChild",
      })
        .then(() => {
          this.$api.delRecycleFiles({ type: "1", id: id }).then((res) => {
            if (res.data.code == "0") {
              this.$message.success("删除成功");
              // this.page.num = 1;
              this.initBin();
            } else {
              this.$message.error(res.data.msg);
            }
          });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    // 清空回收站
    clearRecycle() {
      this.$confirm(
        "清空回收站将删除所有文件,且删除后文件不能恢复,确认清空吗?",
        "确认清空回收站",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          customClass: "addChild",
        }
      )
        .then(() => {
          this.$api.delRecycleFiles({ type: "2", id: "" }).then((res) => {
            if (res.data.code == "0") {
              this.$message.success("清除成功");
              this.initBin();
            } else {
              this.$message.error(res.data.msg);
            }
          });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    // 回收站批量删除
    selectDel() {
      if (this.bindelList.length) {
        this.$confirm("删除后文件将不能恢复,确定删除吗?", "确认批量删除", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          customClass: "addChild",
        })
          .then(() => {
            let ids = this.bindelList.map((item) => item.id).join(",");
            this.$api.delRecycleFiles({ type: "1", id: ids }).then((res) => {
              if (res.data.code == "0") {
                this.$message.success("删除成功");
                // this.page.num = 1;
                this.initBin();
              } else {
                this.$message.error(res.data.msg);
              }
            });
          })
          .catch(() => {
            // this.$message({
            //   type: "info",
            //   message: "已取消删除",
            // });
          });
      }
    },
    /** 回收站 分页 */
    handleCurrentChangeBin(val) {
      this.binPage.num = val;
      this.initBin();
    },
    // 回收站
    handleSizeChangeBin(val) {
      this.binPage.size = val;
      this.binPage.num = 1;
      this.initBin();
    },
    /**回收站 多选 */
    selectRowChangeBin(arr) {
      this.bindelList = arr;
    },
    // 回收站
    handleSelectBin(selection, row) {
      this.bindelList = selection;
    },
    // 回收站
    handleSelectAllBin(arr) {
      this.bindelList = arr;
    },
    // 清除回收站弹窗数据
    clearBin() {
      this.parentId = [];
    },
    //改变输入按键事件
    getInputChange(value, type) {
      let resultValue = value.replace(/[^\w\.\/]/gi, "");
      this.dialogForm[type] = resultValue;
    },
    delFile(id) {
      //单个删除
      this.$confirm("删除后文件不能恢复,确认删除吗?", "确认删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        customClass: "addChild",
      })
        .then(() => {
          this.$api.delFileRequest({ type: "1", id: id + "" }).then((res) => {
            if (res.data.code == "0") {
              this.$message.success("删除成功");
              this.initBin();
            } else {
              this.$message.error(res.data.msg);
            }
          });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    recovery(id, pos) {
      if (pos) {
        this.positionPanel = true;
        this.fileId = id;
      } else {
        this.$confirm("是否确认恢复到原目录?", "恢复到原位", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          customClass: "addChild",
        })
          .then(() => {
            this.$api
              .recoveryFile({ id, type: "1", directoryId: "" })
              .then((res) => {
                if (res.data.code == "0") {
                  this.$message.success("恢复成功");
                  this.initBin();
                } else {
                  this.$message.error(res.data.msg);
                }
              });
          })
          .catch(() => {
            // this.$message({
            //   type: "info",
            //   message: "已取消删除",
            // });
          });
      }
    },
    // 恢复回收站数据
    recoveryToDirectory() {
      if (this.parentId) {
        //发送请求
        this.$api
          .recoveryFile({
            id: this.fileId,
            type: "2",
            directoryId: this.parentId[this.parentId.length - 1],
          })
          .then((res) => {
            if (res.data.code == "0") {
              this.positionPanel = false;
              this.$message.success("恢复成功");
              this.initBin();
            } else {
              this.$message.error(res.data.msg);
            }
          });
      } else {
        this.$message.error("请选择目录");
      }
    },
    showAttachment(id) {
      //查看附件
      this.attachmentVisibleId = id;
      this.$api.getAttachmentInfo({ id }).then((res) => {
        if (res.data.code == "0" && res.data.data && res.data.data.length) {
          this.attachmentData = res.data.data;
          this.attachmentVisible = true;
        } else {
          this.$message.error("附件为空");
        }
      });
    },
    /** 初始化 */
    init() {
      if (this.form.keyword == "") {
        this.form.keyword = null;
      }
      // this.page.num = 1;
      this.tableLoading = true;
      this.$api
        .getFileList(
          Object.assign({
            current: this.page.num,
            size: this.page.size,
            fileName: this.form.keyword,
            directoryId: this.form.directoryId,
          })
        )
        .then((res) => {
          this.tableLoading = false;
          if (res.data.code == "0") {
            this.tableData = res.data.data.records;
            this.page.total = res.data.data.total;
            if (res.data.data.total >= 1) {
              this.pageFlag = true;
            } else {
              this.pageFlag = false;
            }
          } else {
            this.tableData = [];
            this.page.total = 0;
            this.pageFlag = false;
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {
          this.tableLoadingBin = false;
        });
    },
    clickChildrenNode(node) {
      //点击目录子节点
      // if (this.directoryId == node.id || node.directoryLevel != 3) return;
      if (node.directoryName == "回收站") {
        this.isRecycleBin = true;
        this.initBin();
      } else if (!node.child) {
        this.currentCatalogId = node.createBy;
        this.isRecycleBin = false;
        this.page.num = 1;
        this.form.directoryId = node.id;
        this.init();
      }
    },
    // 查询
    searchFun() {
      this.page.num = 1;
      this.init();
    },
    // 重置按钮
    resetFun() {
      this.form.keyword = "";
      // this.form.directoryId = "";
      this.page.num = 1;
      this.init();
    },
    /** 表单-新增 */
    handleAdd() {
      this.dialogVisible = true;
      this.title = "0";
      // this.dialogForm = {
      //   sort: "",
      // };
    },
    /** 对话框-新增 */
    dialogAdd(dialogForm) {
      this.$refs[dialogForm].validate((valid) => {
        if (valid) {
          this.editLoading = true;
          this.$api
            .addObjDict(this.dialogForm)
            .then((res) => {
              if (res.data.code == "0") {
                this.$message.success("添加成功");
                this.init();
                this.handleClose();
              } else {
                this.$message.error(res.data.msg);
              }
              this.editLoading = false;
            })
            .catch((error) => {
              this.editLoading = false;
            });
        }
      });
    },
    /** 对话框-修改提交 */
    handleEditBtn(dialogForm) {
      this.$refs[dialogForm].validate((valid) => {
        if (valid) {
          this.editLoading = true;
          this.$api
            .putObjDict(this.dialogForm)
            .then((res) => {
              if (res.data.code == "0") {
                this.$message.success("修改成功");
                this.dialogVisible = false;
                this.init();
                this.handleClose();
              } else {
                this.$message.error(res.data.msg);
              }
              this.editLoading = false;
            })
            .catch((error) => {
              this.editLoading = false;
            });
        }
      });
    },
    /** 对话框-关闭事件 */
    handleClose() {
      this.dialogVisible = false;
      this.$nextTick(() => {
        this.dialogForm = {
          type: "",
          name: "",
          value: "",
          description: "",
          sort: "",
        };
        this.$refs["dialogForm"].clearValidate();
      });
    },
    editOrShow(type, id) {
      /** 编辑或者查看信息 */
      let form = "";
      if (
        sessionStorage.getItem("dataManage") &&
        JSON.parse(sessionStorage.getItem("dataManage")).form
      ) {
        form = JSON.parse(sessionStorage.getItem("dataManage")).form;
      }
      let obj = {
        directoryId: this.form.directoryId,
        form: form,
      };
      sessionStorage.setItem("dataManage", JSON.stringify(obj));
      this.$router.push({
        path: type == "show" ? "showManage" : "createManage",
        query: {
          id,
        },
      });
    },
    delFiles() {
      if (this.selectData.length) {
        this.$confirm("你确定要删除这些文件吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          customClass: "addChild",
        })
          .then(() => {
            let ids = this.selectData.map((item) => item.id).join(",");
            this.$api.delFile({ id: ids }).then((res) => {
              if (res.data.code == "0") {
                this.$message.success("删除成功");
                // this.page.num = 1;
                this.init();
                this.selectData = [];
              } else {
                this.$message.error(res.data.msg);
              }
            });
          })
          .catch(() => {
            // this.$message({
            //   type: "info",
            //   message: "已取消删除",
            // });
          });
      } else {
        this.$message.warning("请至少选择一个文件进行删除");
      }
    },
    /** 行内-删除 */
    handleDel(id) {
      // `确定要删除该条数据吗?`
      const h = this.$createElement;
      this.$confirm(
        h("p", null, [
          h(
            "span",
            {
              style:
                "color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;",
            },
            "确定要删除该条数据？"
          ),
          h(
            "div",
            {
              style:
                "color: rgba(0, 0, 0, 0.65);font-size: 14px;margin-top: 10px",
            },
            "是否确定删除该条数据？"
          ),
        ]),
        "操作确认",
        {
          type: "warning",
          iconClass: "el-icon-question",
          closeOnClickModal: false,
          // customClass: "warningMessage",
          customClass: "addChild",
        }
      ).then(() => {
        this.$api.delFile({ id }).then((res) => {
          if (res.data.code == "0") {
            this.$message.success("删除成功");
            // this.page.num = 1;
            this.init();
          } else {
            this.$message.error(res.data.msg);
          }
        });
      });
    },
    /** 分页 */
    handleCurrentChange(val) {
      this.page.num = val;
      this.init();
    },
    handleSizeChange(val) {
      this.page.size = val;
      this.page.num = 1;
      this.init();
    },
    showFile(item) {
      console.log(555, item);
      if (item.type == "2") {
        this.onlinePreviewVisible = true;
        this.fileAttachment = item;
        this.fileAttachment.previewType = "video";
      } else if (item.type == "3") {
        this.onlinePreviewVisible = true;
        this.fileAttachment = item;
        this.fileAttachment.previewType = "image";
      } else if (item.type == "1") {
        if (
          item.pdf_url ||
          item.url.indexOf("pdf") != -1 ||
          item.url.indexOf("PDF") != -1
        ) {
          this.onlinePreviewVisible = true;
          this.fileAttachment = {
            previewType: "pdf",
            url: item.pdf_url,
            attachment_name: item.attachment_name,
            attachment_type: item.attachment_type,
          };
        } else {
          this.downLoading = false;
          this.$message.error("暂不支持在线预览，请下载后查看");
          return;
        }
        // this.onlinePreviewVisible = true;
        // this.fileAttachment = item;
        // this.fileAttachment.previewType = item.attachment_type;
      } else {
        this.$message.error("错误");
      }
      this.$api.onlinePreview({ id: item.id });
    },

    downLoadFile(id, type, fileType) {
      if (fileType == 2 && type == "pdf") {
        return;
      }
      //下载文件
      let url = {
        file:
          "/dataDirectory/data-attachment/downloadFile?id=" + id + "&type=1",
        pack1:
          "/dataDirectory/data-attachment/batchDownloadFile?id=" +
          this.attachmentVisibleId +
          "&type=1",
        pack2:
          "/dataDirectory/data-attachment/batchDownloadFile?id=" +
          this.attachmentVisibleId +
          "&type=2",
        pdf: "/dataDirectory/data-attachment/downloadFile?id=" + id + "&type=2",
      };
      if (!url[type]) {
        this.$message.error("暂无接口");
        return;
      }
      this.downLoading = true;
      downloadFile({ url: url[type] }).then(() => {
        this.downLoading = false;
      });
    },
    /**多选 */
    selectRowChange(arr) {
      this.selectData = arr;
    },
    handleSelect(selection, row) {
      this.selectData = selection;
    },
    handleSelectAll(arr) {
      this.selectData = arr;
    },
    closeOnlinePreviewDialog() {
      this.fileAttachment = {};
      this.onlinePreviewVisible = false;
    },
    addFile() {
      this.$router.push({
        path: "/createManage",
        query: {
          directoryId: this.form.directoryId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/select_list.scss";
.dict {
  background-color: #ffffff;
  height: calc(100% - 64px);
  padding: 34px 30px 30px 30px;
  .form-header-btn {
    margin-bottom: 24px;
  }
  .tableList {
    height: calc(100% - 62px);
  }
}
.videoBox {
  width: 100%;
  height: 450px;
  overflow: hidden;
  background: #000;
  video {
    height: 100%;
    margin-left: 50%;
    transform: translateX(-50%);
  }
}
.activeRar {
  // padding: 20px;
  margin-bottom: 16px;
  display: inline-block;
}
.wordColor {
  color: #909399;
}
.mianDiv {
  margin-bottom: 20px;
}
/deep/.container {
  height: 100%;
}
/deep/.aside {
  border-right: 1px solid #909399;
  overflow: none;
}
.wordColor2 {
  color: #409eff;
}
.wordColor3 {
  color: #999;
}
.downLoadFile {
  text-align: right;
  margin-bottom: 16px;
}
/deep/.el-tree {
  .el-tree-node {
  }
}
/deep/.el-tree-node__content {
  height: 40px !important;
}
/deep/.el-tree-node {
  .el-tree-node__content {
    .custom-tree-node {
      span {
        width: 13em;
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        box-sizing: border-box;
        color: #3d3e4f;
        font-size: 16px;
        // line-height: 40px;

        // display: inline-block;
      }
    }
  }
  .el-tree-node__children {
    .el-tree-node {
      .el-tree-node__content {
        .custom-tree-node {
          span {
            width: 12em;
            display: inline-block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            box-sizing: border-box;
            // line-height: 40px;
          }
        }
      }
      .el-tree-node {
        .el-tree-node__content {
          .custom-tree-node {
            span {
              width: 10em;
              display: inline-block;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              box-sizing: border-box;
              // line-height: 40px;
            }
          }
        }
      }
    }
  }
}
.custom-tree-node {
  // .custom-tree-node{}
  span {
    // width: 8em;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    box-sizing: border-box;
  }
}
.fileType {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    margin-right: 10px;
  }
}
.nodeLabel {
  color: #3d3e4f;
  font-size: 16px;
  line-height: 20px;
  display: inline-block;
}
</style>
<style>
.addChild .el-button {
  width: 96px;
  /* height: 38px; */
}
</style>
